.input {
    display: grid;
    grid-template-rows: 4fr 1fr;
    border-right: 1px solid black;
    overflow: auto;
}

/*
.data-input {
    text-align: center;
}

.data-input-parameter {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    margin: auto;
}

input.parameter-input {
    height: 3vh;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
}
*/