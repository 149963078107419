.critical-path-item {
    padding-left: 0px !important;
}

/* Unused */

.metrics .badge {
    margin-right: 3px;
}

.metrics-output {
    border-left: solid 1px black;
}

.kpi-row {
    display: flex;
    justify-content: space-between;
    padding-left: 1vmax;
    background-color: #ccc;
    border-bottom: 1px solid white;
}

.kpi-data {
    background-color: #ccc;
    padding-left: 1vmax;
}