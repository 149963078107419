/*
.create-order {
    display: grid;
    grid-template-columns: 1fr 3fr;
    height: 100%;
}
*/

.phase-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.phase {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    padding-bottom: 50px;
    margin-bottom: 10px;
}

.phase .badge,
.phase .hint-text {
    margin-right: 3px;
    margin-bottom: 3px;
}

.phase .hint-text {
    color: #999;
    font-size: 0.9rem;
    user-select: none;
}

.phase .delete-button {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 10px;
    padding: 6px;
    color: #666666;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
}

.phase .delete-button:hover {
    background-color: #f5f5f5;
}

.add-phase {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
}

.add-phase button {
    display: flex;
    align-items: center;
    padding: 8px;
    margin-left: 5px;
    color: #666666;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
}

.add-phase button:hover {
    background-color: #f5f5f5;
}

.add-phase input[type="text"] {
    flex: 1;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-right: none;
}

.phase .delete-icon-button {
    color: #fff;
    background: black;
    border: none;
    border-radius: 3px;
    padding: 0px;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    margin-left: 8px;
    margin-top: 1px;
    margin-bottom: 1px;
    vertical-align: sub;
}

.phase .delete-icon-button:hover {
    color: #f5f5f5;
}

.phase .bi-x-square-fill {
    font-size: 1.2em;
}

.phase .bi-x-square-fill::before {
    display: block;
    margin: auto;
}
