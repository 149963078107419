/*
.create-action {
    display: grid;
    grid-template-columns: 1fr 3fr;
    height: 100%;
}
*/

.action-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.action {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    padding-bottom: 50px;
    margin-bottom: 10px;
}

.action .badge,
.action .hint-text {
    margin-right: 3px;
    margin-bottom: 3px;
}

.action .hint-text {
    color: #999;
    font-size: 0.9rem;
    user-select: none;
}

.action .delete-button {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 10px;
    padding: 6px;
    color: #666666;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
}

.action .delete-button:hover {
    background-color: #f5f5f5;
}

.add-action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
}

.add-action button {
    display: flex;
    align-items: center;
    padding: 8px;
    margin-left: 5px;
    color: #666666;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
}

.add-action button:hover {
    background-color: #f5f5f5;
}

input.input-action {
    flex: 1;
    padding: 10px;
    font-size: 16px;
    height: 33px;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: left;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

input.input-action::placeholder {
    color: #999;
    font-size: 0.9rem;
}

.action .delete-icon-button {
    color: #fff;
    background: black;
    border: none;
    border-radius: 3px;
    padding: 0px;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    margin-left: 8px;
    margin-top: 1px;
    margin-bottom: 1px;
    vertical-align: sub;
}

.action .delete-icon-button:hover {
    color: #f5f5f5;
}

.action .bi-x-square-fill {
    font-size: 1.2em;
}

.action .bi-x-square-fill::before {
    display: block;
    margin: auto;
}

h1 {
    font-size: 32px !important;
    margin-bottom: 20px;
    text-align: center;
}
